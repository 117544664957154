<template>
  <div class="app-div">
    <!-- <div class="relative" v-if="$store.getters.isAuthenticated"> -->
    <SideBar ref="sidebar" :islogin="$store.getters.isAuthenticated" />
    <!-- <v-button
      v-if="$store.getters.isSidebarOpen"
      class="menu-toggle absolute inset-y-9 -right-2 h-6 w-6"
      @click="toggleMenu"
    >
      <img src="@/assets/icons/collapse-arrow.svg" alt="Collapse" width="25" />
    </v-button>
    <v-button
      v-if="!$store.getters.isSidebarOpen"
      class="menu-toggle absolute inset-y-9 -right-2 h-6 w-6"
      @click="toggleMenu"
    >
      <img src="@/assets/icons/open-arrow.svg" alt="Open" width="25" />
    </v-button> -->
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
export default {
  components: { SideBar },
  methods: {
    toggleMenu() {
      this.$store.dispatch("toggleSidebar", !this.$store.getters.isSidebarOpen);
    },
  },
};
</script>

<style lang="scss">
:root {
	--primary: #6CA626;
	--secondary: #3D4250;
  --tertiary: #2C2C2C;
	--grey: #F1F1F2;
	--dark: #1e293b;
  --heading: #333333,
  --light: #f1f1f1;
  --paragraph: #717171,
  --background: #EBEBEB,
	--sidebar-width: 250px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app-div {
	display: flex;
  background-color: #EBEBEB;
	main {
		flex: 1 1 0;
		// padding: 2rem;
    overflow: auto;
		@media (max-width: 1024px) {
			// padding-left: 5rem;
      .modal-dialog{
        // padding-left: 6.5rem;
    }
		}
	}
  // CSS for mobile and tablet views
  @media (max-width: 1024px) {
    .info-modal{
      left:18% !important;
    }
  }
}
</style>
