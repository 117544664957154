/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore } from "vuex";

export default createStore({
  state: {
    sidebarOpen: true,
    admin: null,
    isLoggedIn: false,
    token: null,
    loggingOut: false,
    admin_workspaces: [],
    selected_admin_wrokspace: null,
    sidebar_routes: [],
  },
  getters: {
    isAuthenticated(state) {
      return state.isLoggedIn;
    },
    getAdminDetails(state) {
      return state.admin;
    },
    getSidebarRoutes(state) {
      return state.sidebar_routes;
    },
    isSidebarOpen(state) {
      return state.sidebarOpen;
    },
    isSuperAdmin(state) {
      return state.admin && state.admin["role"] === "superadmin";
    },
  },
  mutations: {
    SHOW_SIDEBAR(state, value) {
      state.sidebarOpen = value;
      localStorage.setItem("is_expanded", value);
    },
    SET_ADMIN(state: any, payload) {
      const newAdmin = { ...payload.admin };
      if (
        !payload.pictureUpdate &&
        state.admin &&
        state.admin.profile_picture &&
        state.admin.profile_picture.key
      ) {
        newAdmin.profile_picture = state.admin.profile_picture;
      }
      state.admin = newAdmin;
      localStorage.setItem("admin", JSON.stringify(newAdmin));
    },
    SET_TOKEN(state, token) {
      state.token = token;
      state.isLoggedIn = true;
      localStorage.setItem("token", token);
    },
    SET_ROUTES(state, routes) {
      state.sidebar_routes = routes;
    },
    LOGOUT(state) {
      state.admin = null;
      state.isLoggedIn = false;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
    },
    REMOVE_USER(state) {
      state.admin = null;
      state.isLoggedIn = false;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
    },
  },
  actions: {
    login({ commit }, response) {
      commit("SET_ADMIN", { admin: response.admin });
      commit("SET_TOKEN", response.token);
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    removeUser({ commit }) {
      commit("REMOVE_USER");
    },
    updatesidebar({ commit }, routes) {
      commit("SET_ROUTES", routes);
    },
    toggleSidebar({ commit }, value) {
      commit("SHOW_SIDEBAR", value);
    },
  },
  modules: {},
});
