import { ref } from "vue";

export const inviteModal = ref(false);
export const confirmModal = ref(false);
export const commonModal = ref(false);
export const customerModal = ref(false);
export const supplierModal = ref(false);
export function openInviteAdminModal() {
  inviteModal.value = true;
}

export function closeInviteAdminModal() {
  inviteModal.value = false;
}

export function openConfirmModal() {
  confirmModal.value = true;
}

export function closeConfirmModal() {
  confirmModal.value = false;
}
export function openCommonModal() {
  commonModal.value = true;
}

export function closeCommonModal() {
  commonModal.value = false;
}
export function openCustomerModal() {
  customerModal.value = true;
}

export function closeCustomerModal() {
  customerModal.value = false;
}
export function openSupplierModal() {
  supplierModal.value = true;
}

export function closeSupplierModal() {
  supplierModal.value = false;
}
