import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";
import "./assets/main.css"; // Import Tailwind CSS
// import upperFirst from "lodash/upperFirst";
// import camelCase from "lodash/camelCase";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import "notyf/notyf.min.css";
import "notyf/notyf.min.js";
import { Notyf } from "notyf";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/css/plugins/draggable.min.css";
import "froala-editor/js/plugins/entities.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/css/plugins/special_characters.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/css/plugins/video.min.css";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/css/plugins/line_breaker.min.css";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/css/plugins/help.min.css";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/css/plugins/fullscreen.min.css";
// const requireComponent = require.context(
//   "./components",
//   false,
//   /Base[A-Z]\w+\.(vue|js)$/
// );

const app = createApp(App);

// requireComponent.keys().forEach((fileName) => {
//   const componentConfig = requireComponent(fileName);

//   const componentName = upperFirst(
//     camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
//   );

//   app.component(componentName, componentConfig.default || componentConfig);
// });
app.use(store);
app.use(router);
const notyf = new Notyf({
  duration: 6000,
  position: { x: "right", y: "top" },
  dismissible: true,
  // Set your global Notyf configuration here
});
app.provide("notyf", notyf);
app.use(LoadingPlugin, { color: "#6CA626" });
app.mount("#app");
