<template>
  <div
    v-if="showModal"
    id="commonmmodal"
    tabindex="-1"
    aria-hidden="true"
    class="modal-dialog fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-hidden md:inset-0"
  >
    <div class="relative" :class="width">
      <!-- Modal content -->
      <div class="relative bg-white rounded-none shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-start justify-between py-3 px-6 border-b rounded-t dark:border-gray-600 bg-[#E4E5EA]"
        >
          <h3
            class="text-xl font-semibold text-gray-800 lg:text-1xl dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            id="closeButton"
            @click="hideModal()"
            type="button"
            class="text-gray-400 bg-white rounded-full text-sm p-1.5 ml-auto inline-flex items-center"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Modal body -->
        <slot />
        <div
          class="flex items-end justify-end px-8 pt-2 pb-6"
          v-if="showFooter"
        >
          <!-- <button
            @click="hideModal()"
            type="submit"
            class="mr-4 bg-background text-secondary font-semibold py-3 px-7 rounded-none hover:bg-[#f6f6f6] focus:ring-2 focus:ring-[#f6f6f6] focus:border-transparent"
          >
            {{ cancelBtnText }}
          </button> -->
          <button
            @click="onSave()"
            type="submit"
            class="bg-primary text-white font-semibold py-3 px-7 rounded-none hover:bg-[#77b828] focus:ring-2 focus:ring-[#77b828] focus:border-transparent"
          >
            {{ confirmBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { commonModal, defineComponent } from "vue";
import { Modal } from "flowbite";
import { closeCommonModal } from "@/state/authState";

export default defineComponent({
  name: "CommonModal",
  props: {
    title: {
      type: String,
      default: "",
    },
    cancelBtnText: {
      type: String,
      default: "Cancel",
    },
    confirmBtnText: {
      type: String,
      default: "Save",
    },
    width: {
      type: String,
      default: "w-full max-w-md max-h-full",
    },
    height: {
      type: String,
      default: "h-[calc(100%-1rem)] max-h-full",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      commonModal,
      closeCommonModal,
    };
  },
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    const $modalElement = document.querySelector("#commonmmodal");

    const modalOptions = {
      backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
      backdrop: "dynamic", //static to avoid closing the modal on the backdrop click
      onHide: () => {
        closeCommonModal();
        this.$emit("onHide");
      },
    };

    if ($modalElement) {
      this.modal = new Modal($modalElement, modalOptions);
      this.modal.show();
    }
  },
  methods: {
    hideModal() {
      this.modal.hide();
      closeCommonModal();
      this.$emit("onHide");
    },
    onSave() {
      // this.modal.hide();
      // closeCommonModal();
      this.$emit("onSave");
    },
  },
  computed: {
    showModal() {
      if (commonModal == false && this.modal) {
        this.modal.hide();
      }
      return true;
    },
  },
});
</script>
