import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import LoginView from "../views/auth/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/orders",
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password/:code",
    name: "Reset Password",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/ResetPassword.vue"),
  },
  {
    path: "/admins",
    name: "AdminsView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminsView.vue"),
  },
  {
    path: "/customers",
    name: "CustomersView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CustomersView.vue"),
  },
  {
    path: "/suppliers",
    name: "SuppliersView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SuppliersView.vue"),
  },
  {
    path: "/promotions",
    name: "PromotionsView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PromotionsView.vue"),
  },
  {
    path: "/orders",
    name: "OrdersView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OrdersView.vue"),
  },
  {
    path: "/activities",
    name: "ActivityAudit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ActivityAudit.vue"),
  },
  {
    path: "/settings",
    name: "SettingsView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SettingsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
