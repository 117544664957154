/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useApi } from '/@src/composable/useApi'
import axios from "axios";
import { API_ENDPOINT } from "../../environment";
const api = axios.create({
  baseURL: API_ENDPOINT,
});
export function useApi() {
  return api;
}
let tokenVal = "";
const apiClient = useApi();

export default {
  getHeaders() {
    // const token = tokenVal;
    const token = localStorage.getItem("token") || "";
    tokenVal = token;
    return { headers: { Authorization: "Bearer " + token } };
  },
  getToken() {
    return tokenVal;
  },
  get(url: string) {
    return apiClient.get(url, this.getHeaders());
  },
  getForFile(url: string) {
    return apiClient.get(url, {
      ...this.getHeaders(),
      responseType: "blob",
    });
  },
  put(url: string, data: any) {
    return apiClient.put(url, data, this.getHeaders());
  },
  post(url: string, data: any) {
    return apiClient.post(url, data, this.getHeaders());
  },
  delete(url: string, data?: any) {
    const config = {
      ...this.getHeaders(),
      data: data || undefined,
    };
    return apiClient.delete(url, config);
  },
  noTokenPost(url: string, data: any) {
    // console.log(data,url);
    return apiClient.post(url, data);
  },
  noTokenGet(url: string) {
    return apiClient.get(url);
  },
};
